// Theme JS

// Import styles
import '../scss/theme.scss';
// Import Bootstrap
import 'bootstrap';

$(document).ready(function() {
  // Force Break Industry Titles at the and symbol
  $('.hc-layout-industries-title:contains("&")').each(function(){
    let t = $(this).text();
    $(this).html(t.replace("&","&<br/>"));
  });

  const imageLinks = document.getElementsByClassName('hc-image-link');
  for (const image of imageLinks) {
    image.addEventListener('click', () => {
      location.href = image.dataset.href;
    });
  }

});


window.Hawkins = {
    // Toggle Google Maps
    ToggleMap: (visibleId, visibleClass = '.hc-layout-contact_map_item') => {
        $(visibleClass).hide();
        $(`#${visibleId}`).show();
    },
};


// Off canvas menu
// function darken_screen(yesno){
// 	if( yesno == true ){
// 		document.querySelector('.screen-darken').classList.add('active');
// 	}
// 	else if(yesno == false){
// 		document.querySelector('.screen-darken').classList.remove('active');
// 	}
// }
//
// function close_offcanvas(){
// 	darken_screen(false);
// 	document.querySelector('.mobile-offcanvas.show').classList.remove('show');
// 	document.body.classList.remove('offcanvas-active');
// }
//
// function show_offcanvas(offcanvas_id){
// 	darken_screen(true);
// 	document.getElementById(offcanvas_id).classList.add('show');
// 	document.body.classList.add('offcanvas-active');
// }
//
// document.addEventListener("DOMContentLoaded", function(){
// 	document.querySelectorAll('[data-trigger]').forEach(function(everyelement){
//
// 		let offcanvas_id = everyelement.getAttribute('data-trigger');
//
// 		everyelement.addEventListener('click', function (e) {
// 			e.preventDefault();
//         	show_offcanvas(offcanvas_id);
//
// 		});
// 	});
//
// 	document.querySelectorAll('.btn-close').forEach(function(everybutton){
//
// 		everybutton.addEventListener('click', function (e) {
// 			e.preventDefault();
//         	close_offcanvas();
//   		});
// 	});
//
// 	document.querySelector('.screen-darken').addEventListener('click', function(event){
// 		close_offcanvas();
// 	});
//
// });
